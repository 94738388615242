<template>
    <div is="detail">
    <Items v-for="(category, key) in categories" :category="category" :categoryID="key" :key="key" />
      </div>

</template>
<style>
.slick-bg:before {
 display: none;
}
</style>
<script>
import { core } from '../../config/pluginInit'
import Items from './Components/ListItems/ListItems'
import ApiService from '../../services/api'
export default {
  name: 'MainPage',
  data () {
    return {
      categories: []
    }
  },
  components: {
    Items
  },
  methods: {
 
    async getContentCategories () {
      ApiService.getLastContentCategories().then((response) => {
        this.$store.commit('setPoints', response.data.user_points)
        this.categories = response.data.data
      })
    }
  },
  mounted () {
    core.index()
    this.getContentCategories()
  }
}
</script>
